@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/material-icons.0509ab09.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/roboto-regular.11eabca2.ttf) format("truetype");
}

.material-icons {
  font-family: "Material Icons", "sans-serif";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

html {
  height: 100%; }
  html body {
    position: relative;
    min-height: 100%;
    background-color: #f2f2f2; }

